import { Layout } from "antd";
import React from "react";

const AppFooter = () => {
  return (
    <Layout.Footer style={{ textAlign: "center" }}>
      POFO DCE by CHPIR
    </Layout.Footer>
  );
};

export default AppFooter;
