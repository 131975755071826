const INSTRUMENTS = {
  //fetch
  LOAD: "INSTRUMENTS_LOAD",
  LOAD_SUCCESS: "INSTRUMENTS_LOAD_SUCCESS",
  LOAD_FAIL: "INSTRUMENTS_LOAD_FAIL",
  //create
  CREATE: "INSTRUMENT_CREATE",
  CREATE_SUCCESS: "INSTRUMENT_CREATE_SUCCESS",
  CREATE_FAIL: "INSTRUMENT_CREATE_FAIL",
  //update
  UPDATE: "INSTRUMENT_UPDATE",
  UPDATE_SUCCESS: "INSTRUMENT_UPDATE_SUCCESS",
  UPDATE_FAIL: "INSTRUMENT_UPDATE_FAIL",
  //destroy
  DELETE: "INSTRUMENT_DELETE",
  DELETE_SUCCESS: "INSTRUMENT_DELETE_SUCCESS",
  DELETE_FAIL: "INSTRUMENT_DELETE_FAIL",
};

export { INSTRUMENTS };
